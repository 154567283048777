import Contact from "@cospex/client/tracker/components/Contact";

export default function ContactPage() {
  return (
    <Contact
      contactNumbers={{
        en: "+44 20 7898 2574",
      }}
    />
  );
}
