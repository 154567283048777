import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./lang/en.json";
import fr from "./lang/fr.json";

const getLanguage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const accepted = ["en", "fr"];
  const lang =
    localStorage.getItem("lng") ||
    urlParams.get("lang") ||
    window.navigator.language.split("-")[0];

  return accepted.includes(lang) ? lang : "en";
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  lng: getLanguage(),
});

export default i18n;
